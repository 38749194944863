var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"w-full mx-auto sm:px-6 lg:px-0 lg:grid lg:grid-cols-10 lg:gap-8"},[_c('modal-create-block',{attrs:{"trigger":_vm.createToogle,"date":_vm.selectedDateString},on:{"toggle":function (event) {
        _vm.createToogle = !_vm.createToogle;
        event == 'save' ? _vm.getBlocks() : '';
      }}}),_c('modal-finish-block',{attrs:{"trigger":_vm.finishToogle},on:{"toggle":function (event) {
        event.status == 'save' ? _vm.finishBlock(_vm.finishToogle, event.type) : '';
        _vm.finishToogle = null;
      }}}),_c('modal-create-schedule',{attrs:{"trigger":_vm.scheduleToogle},on:{"toggle":function (event) {
        _vm.scheduleToogle = !_vm.scheduleToogle;
        event == 'save' ? _vm.getBlocks() : '';
      }}}),_c('modal-create-schedule-delete',{attrs:{"trigger":_vm.scheduleDeleteToogle},on:{"toggle":function (event) {
        _vm.scheduleDeleteToogle = !_vm.scheduleDeleteToogle;
        event == 'save' ? _vm.getBlocks() : '';
      }}}),_c('modal-delete-block',{attrs:{"trigger":_vm.deleteToogle},on:{"toggle":function (event) {
        _vm.deleteToogle = null;
        event == 'save' ? _vm.getBlocks() : '';
      }}}),_c('modal-delete-blocked-block',{attrs:{"trigger":_vm.deleteBlockedToogle},on:{"toggle":function (event) {
        _vm.deleteBlockedToogle = null;
        event == 'save' ? _vm.getBlocks() : '';
      }}}),_c('modal-delete-block-all',{attrs:{"trigger":_vm.deleteToogleAll},on:{"toggle":function (event) {
        _vm.deleteToogleAll = null;
        event == 'save' ? _vm.getBlocks() : '';
      }}}),(_vm.editBlockToogle != null)?_c('modal-edit-block',{attrs:{"trigger":_vm.editBlockToogle},on:{"toggle":function (event) {
        _vm.editBlockToogle = null;
        event == 'save' ? _vm.getBlocks() : '';
      }}}):_vm._e(),_c('modal-user-block',{attrs:{"trigger":_vm.userBlockToogle},on:{"toggle":function (event) {
        _vm.userBlockToogle = null;
        event == 'save' ? _vm.getBlocks() : '';
      }}}),_c('div',{staticClass:"col-span-1 md:col-span-4"},[_c('nav',{staticClass:"sticky top-24 divide-y divide-gray-300",attrs:{"aria-label":"Sidebar"}},[_c('div',{staticClass:"pb-8"},[_c('calendar-component',{attrs:{"day":_vm.day,"month":_vm.month,"year":_vm.year,"daysAvailable":_vm.daysGray},on:{"updateDay":function (d) { return (_vm.day = d); },"updateMonth":function (m) { return (_vm.month = m); },"updateYear":function (y) { return (_vm.year = y); }}}),_c('div',{staticClass:"mt-5 w-full border border-dotted border-gray-300 p-5 rounded-md flex flex-col justify-center items-start"},[_c('span',{staticClass:"w-full mb-2 text-md text-gray-600 font-bold text-left"},[_vm._v("Editar horario de trabajo")]),_c('div',{staticClass:"pl-5 w-full"},[_c('button',{staticClass:"group flex mb-2 items-center px-3 py-2 text-sm font-medium rounded-md w-full",class:_vm.disableCreation
                  ? 'cursor-not-allowed text-gray-500'
                  : 'text-gray-900 bg-gray-200 hover:bg-gray-300',attrs:{"disabled":_vm.disableCreation},on:{"click":function () {
                  !_vm.disableCreation ? (_vm.createToogle = !_vm.createToogle) : '';
                }}},[_c('span',{staticClass:"truncate"},[_vm._v("Agendar sobrecupo")])]),_c('button',{staticClass:"text-red-600 hover:text-red-800 group flex items-center px-3 py-2 text-sm font-medium rounded-md",on:{"click":function () {
                  _vm.scheduleDeleteToogle = !_vm.scheduleDeleteToogle;
                }}},[_c('span',{staticClass:"truncate"},[_vm._v("Borrar multiples bloques del horario")])])])])],1)])]),_c('div',{staticClass:"col-span-1 md:col-span-6"},[(_vm.blocks.filter(function (e) { return e.id; }).length === 0)?_c('div',{staticClass:"w-full border border-gray-200 border-dashed rounded-md"},[_vm._m(0)]):_vm._e(),(_vm.blocks.length !== 0)?_c('div',{staticClass:"mb-4"},_vm._l((_vm.blocks),function(blockElement,blockIndex){return _c('div',{key:blockIndex,staticClass:"border border-gray-200 overflow-hidden rounded-md bg-white mb-3 grid grid-cols-12"},[_c('div',{staticClass:"bg-gray-200 col-span-2 border-r-4 border-dashed border-gray-300 flex flex-col justify-center items-center"},[(blockElement.id)?_c('span',{staticClass:"text-sm font-semibold mb-2",class:blockElement.is_not_available && !blockElement.is_blocked
                ? 'bg-gray-300 px-2 py-1 rounded-md'
                : blockElement.is_blocked
                ? 'bg-gray-800 px-2 py-1 rounded-md text-white'
                : 'bg-green-500 px-2 py-1 rounded-md text-white'},[_vm._v(_vm._s(_vm.formatToHour(blockElement.start_date)))]):_vm._e(),(!blockElement.id)?_c('span',{staticClass:"text-sm font-semibold mb-2 mt-3 bg-gray-300 text-gray-900 px-2 py-1 rounded-md"},[_vm._v(_vm._s(_vm.formatToHour(blockElement.start_date)))]):_vm._e(),(!blockElement.id)?_c('span',{staticClass:"text-sm font-semibold mb-3 bg-gray-300 text-gray-900 px-2 py-1 rounded-md"},[_vm._v(_vm._s(_vm.formatToHour(blockElement.finish_date)))]):_vm._e(),(
              blockElement.id &&
                blockElement.rate &&
                !blockElement.reservation
            )?_c('span',{staticClass:"text-xs font-semibold"},[_vm._v(" "+_vm._s(_vm.chileanPesos( _vm.blockCalculationPrice(blockElement, blockElement.rate) )))]):_vm._e(),(
              blockElement.id &&
                !blockElement.rate &&
                !blockElement.reservation &&
                _vm.doctor &&
                _vm.doctor.doctor
            )?_c('span',{staticClass:"text-xs font-semibold"},[_vm._v(_vm._s(_vm.chileanPesos( _vm.blockCalculationPrice(blockElement, _vm.doctor.doctor.rate) )))]):_vm._e(),(
              blockElement.id &&
                !blockElement.rate &&
                !blockElement.reservation &&
                _vm.doctor &&
                _vm.doctor.psychologist
            )?_c('span',{staticClass:"text-xs font-semibold"},[_vm._v(_vm._s(_vm.chileanPesos( _vm.blockCalculationPrice(blockElement, _vm.doctor.psychologist.rate) )))]):_vm._e(),(
              blockElement.id &&
                !blockElement.rate &&
                !blockElement.reservation &&
                _vm.doctor &&
                _vm.doctor.superdoctor
            )?_c('span',{staticClass:"text-xs font-semibold"},[_vm._v(_vm._s(_vm.chileanPesos( _vm.blockCalculationPrice(blockElement, _vm.doctor.superdoctor.rate) )))]):_vm._e(),(blockElement.id && blockElement.reservation)?_c('span',{staticClass:"text-xs font-semibold"},[_vm._v(" "+_vm._s(_vm.chileanPesos(blockElement.reservation.purchase.value)))]):_vm._e()]),(!blockElement.id)?_c('div',{staticClass:"col-span-10 border-r border-dashed border-gray-300 bg-gray-200 flex flex-col justify-center items-center"},[_c('span',{staticClass:"text-sm font-semibold py-5"},[_vm._v("Hora disponible")])]):_vm._e(),(blockElement.id)?_c('div',{staticClass:"col-span-7 px-3 py-2 flex flex-col justify-start items-start"},[(blockElement.is_not_available || blockElement.is_blocked)?_c('span',{staticClass:"text-sm text-gray-500 text-left font-semibold"},[(blockElement.is_not_available && !blockElement.is_blocked)?_c('span',[_vm._v("Reservada")]):_vm._e()]):_c('span',{staticClass:"text-md text-gray-500 text-left font-semibold"},[_vm._v("Disponible")]),(blockElement.text)?_c('span',{staticClass:"text-md text-gray-900 text-left font-bold"},[_vm._v(_vm._s(blockElement.text))]):_vm._e(),(blockElement.reservation)?_c('div',{staticClass:"bg-gray-50 flex flex-row justify-start items-center border border-gray-200 px-2 py-1 text-gray-800 text-md rounded-md",on:{"click":function () {
                _vm.$router.push({
                  path: ("/doctor/summary/clinic/" + (blockElement.reservation.purchase.user.id)),
                  query: { block: blockElement.id },
                });
              }}},[_c('svg',{staticClass:"h-4 w-4 mr-2 text-gray-500",attrs:{"xmlns":"http://www.w3.org/2000/svg","viewBox":"0 0 20 20","fill":"currentColor"}},[_c('path',{attrs:{"fill-rule":"evenodd","d":"M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-6-3a2 2 0 11-4 0 2 2 0 014 0zm-2 4a5 5 0 00-4.546 2.916A5.986 5.986 0 0010 16a5.986 5.986 0 004.546-2.084A5 5 0 0010 11z","clip-rule":"evenodd"}})]),_c('div',{staticClass:"w-full text-left text-green-700 hover:text-green-900 underline cursor-pointer"},[_vm._v(" "+_vm._s(_vm.capitalize(blockElement.reservation.purchase.user.names))+" "+_vm._s(_vm.capitalize(blockElement.reservation.purchase.user.lastnames))+" ")])]):_vm._e(),(blockElement.is_finish && blockElement.is_not_available)?_c('button',{staticClass:"text-sm flex flex-row text-gray-500 text-left font-semibold pt-2"},[_c('svg',{staticClass:"h-5 w-5 mr-1",attrs:{"xmlns":"http://www.w3.org/2000/svg","viewBox":"0 0 20 20","fill":"currentColor"}},[_c('path',{attrs:{"fill-rule":"evenodd","d":"M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z","clip-rule":"evenodd"}})]),_c('span',[_vm._v("Estado: "+_vm._s(blockElement.finish_type))])]):_vm._e()]):_vm._e(),(blockElement.id)?_c('div',{staticClass:"col-span-3 border-l border-dashed border-gray-200 px-2 py-2 flex flex-col justify-start items-center"},[(!blockElement.is_not_available)?_c('button',{staticClass:"mb-2 bg-gray-200 w-full flex flex-row-reverse justify-between items-center hover:bg-gray-300 text-gray-700 rounded-md text-sm px-2 py-1",on:{"click":function($event){$event.preventDefault();return (function () {
                _vm.userBlockToogle = blockElement.id.toString();
              })($event)}}},[_c('svg',{staticClass:"h-5 w-5",attrs:{"xmlns":"http://www.w3.org/2000/svg","fill":"none","viewBox":"0 0 24 24","stroke-width":"1.5","stroke":"currentColor"}},[_c('path',{attrs:{"stroke-linecap":"round","stroke-linejoin":"round","d":"M12 6.042A8.967 8.967 0 006 3.75c-1.052 0-2.062.18-3 .512v14.25A8.987 8.987 0 016 18c2.305 0 4.408.867 6 2.292m0-14.25a8.966 8.966 0 016-2.292c1.052 0 2.062.18 3 .512v14.25A8.987 8.987 0 0018 18a8.967 8.967 0 00-6 2.292m0-14.25v14.25"}})]),_c('span',[_vm._v("reservar")])]):_vm._e(),(blockElement.is_not_available)?_c('button',{staticClass:"mb-2 bg-gray-200 w-full flex flex-row-reverse justify-between items-center hover:bg-gray-300 text-gray-700 font-bold rounded-md text-sm px-2 py-1",on:{"click":function () {
                _vm.editBlockToogle = blockElement.id.toString();
              }}},[_c('svg',{staticClass:"h-5 w-5",attrs:{"xmlns":"http://www.w3.org/2000/svg","viewBox":"0 0 20 20","fill":"currentColor"}},[_c('path',{attrs:{"d":"M17.414 2.586a2 2 0 00-2.828 0L7 10.172V13h2.828l7.586-7.586a2 2 0 000-2.828z"}}),_c('path',{attrs:{"fill-rule":"evenodd","d":"M2 6a2 2 0 012-2h4a1 1 0 010 2H4v10h10v-4a1 1 0 112 0v4a2 2 0 01-2 2H4a2 2 0 01-2-2V6z","clip-rule":"evenodd"}})]),_c('span',[_vm._v("editar")])]):_vm._e(),(
              blockElement.session &&
                !blockElement.session.is_activated &&
                !blockElement.session.is_finish &&
                blockElement.is_not_available
            )?_c('button',{staticClass:"mb-2 bg-gray-200 w-full flex flex-row-reverse justify-between items-center hover:bg-gray-300 text-gray-700 font-bold rounded-md text-sm px-2 py-1",on:{"click":function () {
                _vm.activateSession(blockElement);
              }}},[_c('svg',{staticClass:"h-5 w-5",attrs:{"xmlns":"http://www.w3.org/2000/svg","viewBox":"0 0 20 20","fill":"currentColor"}},[_c('path',{attrs:{"d":"M9 2a1 1 0 000 2h2a1 1 0 100-2H9z"}}),_c('path',{attrs:{"fill-rule":"evenodd","d":"M4 5a2 2 0 012-2 3 3 0 003 3h2a3 3 0 003-3 2 2 0 012 2v11a2 2 0 01-2 2H6a2 2 0 01-2-2V5zm9.707 5.707a1 1 0 00-1.414-1.414L9 12.586l-1.293-1.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z","clip-rule":"evenodd"}})]),_c('span',[_vm._v("abrir atención")])]):_vm._e(),(
              blockElement.session &&
                blockElement.session.is_activated &&
                !blockElement.session.is_finish
            )?_c('button',{staticClass:"cursor-not-allowed mb-2 bg-green-500 w-full flex flex-row-reverse justify-center items-center text-white font-bold rounded-md text-sm px-2 py-1"},[_c('span',[_vm._v("atención abierta")])]):_vm._e(),(
              blockElement.session &&
                blockElement.session.is_activated &&
                blockElement.session.is_finish
            )?_c('button',{staticClass:"cursor-not-allowed mb-2 bg-green-500 w-full flex flex-row-reverse justify-center items-center text-white font-bold rounded-md text-sm px-2 py-1"},[_c('span',[_vm._v("sesión finalizada")])]):_vm._e(),(
              blockElement.is_blocked &&
                blockElement.reservation &&
                (!blockElement.session ||
                  (blockElement.session &&
                    !blockElement.session.is_activated))
            )?_c('button',{staticClass:"mb-2 bg-red-50 w-full flex flex-row-reverse justify-between items-center hover:bg-red-100 text-red-700 rounded-md text-sm px-2 py-1",on:{"click":function () {
                blockElement.is_blocked
                  ? _vm.deleteBlockedBlock(blockElement)
                  : '';
              }}},[_c('svg',{staticClass:"h-5 w-5",attrs:{"xmlns":"http://www.w3.org/2000/svg","viewBox":"0 0 20 20","fill":"currentColor"}},[_c('path',{attrs:{"fill-rule":"evenodd","d":"M9 2a1 1 0 00-.894.553L7.382 4H4a1 1 0 000 2v10a2 2 0 002 2h8a2 2 0 002-2V6a1 1 0 100-2h-3.382l-.724-1.447A1 1 0 0011 2H9zM7 8a1 1 0 012 0v6a1 1 0 11-2 0V8zm5-1a1 1 0 00-1 1v6a1 1 0 102 0V8a1 1 0 00-1-1z","clip-rule":"evenodd"}})]),_c('span',[_vm._v("eliminar")])]):_vm._e(),(!blockElement.reservation)?_c('button',{staticClass:"mb-2 bg-red-50 w-full flex flex-row-reverse justify-between items-center hover:bg-red-100 text-red-700 rounded-md text-sm px-2 py-1",on:{"click":function () {
                !blockElement.reservation ? _vm.deleteBlock(blockElement) : '';
              }}},[_c('svg',{staticClass:"h-5 w-5",attrs:{"xmlns":"http://www.w3.org/2000/svg","viewBox":"0 0 20 20","fill":"currentColor"}},[_c('path',{attrs:{"fill-rule":"evenodd","d":"M9 2a1 1 0 00-.894.553L7.382 4H4a1 1 0 000 2v10a2 2 0 002 2h8a2 2 0 002-2V6a1 1 0 100-2h-3.382l-.724-1.447A1 1 0 0011 2H9zM7 8a1 1 0 012 0v6a1 1 0 11-2 0V8zm5-1a1 1 0 00-1 1v6a1 1 0 102 0V8a1 1 0 00-1-1z","clip-rule":"evenodd"}})]),_c('span',[_vm._v("eliminar")])]):_vm._e()]):_vm._e()])}),0):_vm._e(),(_vm.blocks.filter(function (e) { return e.id; }).length !== 0)?_c('div',{staticClass:"flex flex-row w-full justify-end items-center"},[_c('button',{staticClass:"group flex items-center px-3 py-2 text-sm font-medium rounded-md",class:_vm.disableCreation
            ? 'cursor-not-allowed text-red-300'
            : 'text-red-600 bg-red-100 hover:text-red-800',attrs:{"disabled":_vm.disableCreation},on:{"click":function () {
            !_vm.disableCreation ? _vm.deleteBlockAll() : '';
          }}},[_c('span',{staticClass:"truncate"},[_vm._v("Bloquear agenda día completo")])])]):_vm._e()])],1)}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('section',{staticClass:"max-w-lg px-4 py-20 mx-auto space-y-1 text-center"},[_c('img',{staticClass:"mx-auto w-20",attrs:{"src":require("@/assets/img/icons/open-box.png")}}),_c('h2',{staticClass:"text-lg font-medium text-gray-800"},[_vm._v(" Sin horas reservadas ")])])}]

export { render, staticRenderFns }